import React from 'react';
import classNames from 'classnames';
import { SmallStars } from '@wix/ftsystem';
import { classes } from './ready-ratings.st.css';
import { useTranslate } from '../../../use-translate';
import { RATING, RATING_TEXT } from '~ratings/constants/data-hooks';
import { debounce } from 'lodash';
import { useApi } from '~ratings/Widget/components/api-provider/use-api';
import { useSlotContext } from '@wix/widget-plugins-ooi-context';

const findContainer = (el: HTMLElement | null, initialWidth: number): HTMLElement | null => {
  if (!el) {
    return null;
  }
  if (el.scrollWidth > initialWidth) {
    return el;
  } else {
    return findContainer(el.parentElement, initialWidth);
  }
};

/*
 * How collapsing works:
 * 1. We render only the rating container (containerRef) and search for it's closest parent, which is wider than it. This will be our slotContainer.
 * 2. Once we identified our slotContainer - we render the SmallStars and rating info, but they have visibility=hidden to avoid flickering if it appears that we must collapse.
 * 3. Once useEffect runs again with a non-null starsRef - we register the star width and the available slotContainer width for stars (slotContainer width - gap - ratingInfo width). Now the we can remove visibility=hidden during the next render.
 * 4. We observe the slotContainer resizing and recalculate the available container width upon resize.
 * */
export const ReadyRatings = ({
  overall,
  totalReviews,
  onClick,
}: {
  overall: number;
  totalReviews: number;
  onClick?: () => void;
}) => {
  const {
    collapsible,
    isCollapsed,
    registerStarsWidth,
    registerContainer,
    unregisterContainer,
    starsWidth,
  } = useApi((ctx) =>
    ctx.type === 'multi_state'
      ? {
          collapsible: true,
          starsWidth: ctx.state.layout.starsWidth,
          isCollapsed: ctx.state.layout.isCollapsed,
          registerStarsWidth: ctx.actions.registerStarsWidth,
          registerContainer: ctx.actions.registerContainer,
          unregisterContainer: ctx.actions.unregisterContainer,
        }
      : { collapsible: false },
  );
  const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(null);
  const [ratingInfoRef, setRatingInfoRef] = React.useState<HTMLElement | null>(null);
  const [starsRef, setStarsRef] = React.useState<HTMLElement | null>(null);
  const [slotContainer, setSlotContainer] = React.useState<
    | { status: 'READY'; element: HTMLElement }
    | { status: 'UNINITIALIZED' }
    | { status: 'NOT_FOUND' }
  >({ status: 'UNINITIALIZED' });

  const resolveSlotContainer = (element: HTMLElement) => {
    switch (slotContainer.status) {
      case 'UNINITIALIZED': {
        const c = findContainer(element, element.scrollWidth);
        if (c === null) {
          setSlotContainer({ status: 'NOT_FOUND' });
          return element;
        } else {
          setSlotContainer({ status: 'READY', element: c });
          return c;
        }
      }
      case 'READY':
        return slotContainer.element;
      case 'NOT_FOUND':
        return element;
    }
  };

  const slotContext = useSlotContext();
  React.useEffect(() => {
    if (collapsible && containerRef !== null) {
      const container = resolveSlotContainer(containerRef);
      if (starsRef === null || ratingInfoRef === null) {
        return;
      }
      if (starsWidth === 0) {
        registerStarsWidth?.(starsRef.clientWidth);
        return;
      }
      const updateContainerWidth = () => {
        const gap = parseInt(window.getComputedStyle(containerRef).gap, 10);
        if (slotContext.type === 'initialized') {
          registerContainer?.({
            width: container.clientWidth - ratingInfoRef.clientWidth - gap,
            resourceId: slotContext.value.resourceId as string,
          });
        }
      };

      updateContainerWidth();
      const observer = new ResizeObserver(debounce(updateContainerWidth, 500));
      observer.observe(container);
      return () => {
        if (slotContext.type === 'initialized') {
          unregisterContainer?.({
            resourceId: slotContext.value.resourceId as string,
          });
        }
        observer.unobserve(container);
      };
    }
  }, [collapsible, starsRef, containerRef, ratingInfoRef, starsWidth]);
  const t = useTranslate();

  const getTranslationParams = () => ({
    rating: overall.toFixed(1),
    count: totalReviews,
  });

  const children =
    collapsible && slotContainer.status === 'UNINITIALIZED' ? null : (
      <>
        <div ref={setStarsRef}>
          <SmallStars
            className={classNames(
              classes.stars,
              collapsible && starsWidth === undefined ? classes.invisible : '',
            )}
            rating={overall}
            collapsed={isCollapsed}
            a11yLabel={t('ratings-widget.aria-label', getTranslationParams())}
          />
        </div>
        <div
          aria-hidden
          data-hook={RATING_TEXT}
          className={classNames(classes.ratingInfo, collapsible && classes.compact)}
          ref={setRatingInfoRef}
        >
          {t(
            collapsible ? 'ratings-widget.compact-label' : 'ratings-widget.full-label',
            getTranslationParams(),
          )}
        </div>
      </>
    );
  return onClick ? (
    <button
      role="link"
      ref={setContainerRef}
      className={classes.root}
      data-hook={RATING}
      onClick={() => {
        onClick();
      }}
    >
      {children}
    </button>
  ) : (
    <div className={classes.root} data-hook={RATING} ref={setContainerRef}>
      {children}
    </div>
  );
};
