import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

export const emptyStates = {
  blank: 'ratings-settings.empty-state-label.blank',
  plainText: 'ratings-settings.empty-state-label.plain-text',
} as const;

export type EmptyState = keyof typeof emptyStates;

export type SettingsParamsTypes = {
  emptyState: SettingsParamType.Text;
};

export type SettingsParams = typeof settingsParams;

const settingsParams = createSettingsParams<SettingsParamsTypes>({
  emptyState: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }): EmptyState => 'blank',
  },
});
export default settingsParams;
