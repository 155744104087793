import { ApiContext } from './api-context';
import { ApiContextReady } from './api-context-types';
import { useContextSelector } from '~commons/use-context-selector';
import { isShallowEqual } from '~commons/services/is-shallow-equal';

type RuntimeMapper<T> = (ctx: ApiContextReady, host: ApiContextReady['host']) => T;

/**
 * @internal
 * Optimized hook which will trigger rerender only if result is not shallowly equal.
 * Avoid creating new values in it. Don't:
 * const { myNevVal } = useApi(state => ( { nyNewVal: () => state.something }));
 */
export function useApi<T>(mapRuntimeToProps: RuntimeMapper<T>): T {
  return useContextSelector(
    ApiContext,
    (ctx) => {
      if (ctx.type !== 'READY') {
        throw new Error('useApi used outside ApiContext or it was uninitialized');
      }
      const { context } = ctx;
      return mapRuntimeToProps(context, context.host);
    },
    isShallowEqual,
  );
}

// // If used outside of ApiContextGuard
// export function useUnsafeApi<T>(mapRuntimeToProps: RuntimeMapper<T>): T | undefined {
//   return useContextSelector(
//     ApiContext,
//     (ctx) => {
//       if (ctx.type !== 'READY') {
//         return undefined;
//       }
//       const { context } = ctx;
//       return mapRuntimeToProps(context, context.host);
//     },
//     isShallowEqual,
//   );
// }
