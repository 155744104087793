import React from 'react';
import { EMPTY_PLACEHOLDER } from '~ratings/constants/data-hooks';
import { st, classes } from './placeholder.st.css';

export const Placeholder: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div data-hook={EMPTY_PLACEHOLDER} className={st(classes.root, className)}>
      {' '}
    </div>
  );
};
