import React, { FC } from 'react';
import { useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';
import { TPAComponentsProvider } from 'wix-ui-tpa/cssVars';
import { ControllerProps } from '~ratings/common-types';
import { ApiProvider } from '~ratings/Widget/components/api-provider/api-provider';
import { App } from './components/app/app';

const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  const { isRTL, isMobile } = useEnvironment();
  return (
    <TPAComponentsProvider
      // eslint-disable-next-line react-hooks/rules-of-hooks
      value={React.useMemo(() => ({ mobile: isMobile, rtl: isRTL }), [isMobile, isRTL])}
    >
      <ApiProvider {...props}>
        <App />
      </ApiProvider>
    </TPAComponentsProvider>
  );
};

export default Widget;
