import { StyleParamType, wixColorParam, wixFontParam, createStylesParams } from '@wix/tpa-settings';

export type StyleParamsTypes = {
  activeStarColor: StyleParamType.Color;
  inactiveStarColor: StyleParamType.Color;
  scoreFont: StyleParamType.Font;
  scoreFontColor: StyleParamType.Color;
  scoreFontSize: StyleParamType.Number;
  starSize: StyleParamType.Number;
};

export type StylesParams = typeof styleParams;

export const styleParams = createStylesParams<StyleParamsTypes>({
  activeStarColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  inactiveStarColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-3') },
  scoreFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-S', { size: 14 }),
  },
  scoreFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: (props) => {
      const scoreFont = props.getStyleParamValue(styleParams.scoreFont, {
        isMobile: props.isMobile,
        isRTL: props.isRTL,
      });
      return scoreFont.size;
    },
  },
  scoreFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  starSize: { type: StyleParamType.Number, getDefaultValue: () => 18 },
});

export default styleParams;
